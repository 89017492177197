import Index from "./section0/Index"
import Contents from "./section0/Contents"
import "../../styles/section/Section0.sass"

const Section0 = () => {
	return (
        <>
            <Index />
            <Contents />
        </>
    )
}

export default Section0