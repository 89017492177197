import Index from "./section5/Index"
import Contents from "./section5/Contents"
import "../../styles/section/Section5.sass"

const Section5 = () => {
	return (
        <>
            <Index />
            <Contents />
        </>
    )
}

export default Section5