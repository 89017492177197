import Index from "./section1/Index"
import Contents from "./section1/Contents"
import "../../styles/section/Section1.sass"

const Section1 = () => {
	return (
        <>
            <Index />
            <Contents />
        </>
    )
}

export default Section1